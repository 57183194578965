import React from "react"
import Cards from "./Components/Card/Cards"
import { Card } from "@mui/material";



const Main = ()=>{
return(
   <>
   <Cards/>
   </>
 );
};

export default Main;