import React from "react"
import "./Profile.css";
const Profiles:React.FC = () => {
    return (
        <>
        <div className="profile-container">
            <h1>My Profiles</h1>
            <p>Name:Pooja Dhage</p>
            <p>Email:pooja@gmail.com</p>
        </div>
        </>
    );
};
export default Profiles;